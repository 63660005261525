/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AccountService from "../../services/account.service";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
//import ListSubHeader from '@mui/material/ListSubHeader';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import Avatar from '@mui/material/Avatar';
import EditIcon from '@mui/icons-material/Edit';
import StarBorderIcon from '@mui/icons-material/StarOutlineRounded';
import LogOffModal from '../../components/LogOff';
import { Link } from "react-router-dom";
import { Event, ModalView } from '../../components/GoogleAnalytics';
import { jsCurrentAccount } from "../../componentObjects";
import userfavoriteService from "../../services/userfavorite.service";

function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

class MainAppBar extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            auth: true,
            showLogon: false,
            anchorEl: null,
            anchorEl2: null,
            anchorEl3: null,
            open: false,
            openFavorites: false,
            showSidebar: false,

            favoritesList: [],
            favoriteAssets: [],
            favoriteCustomers: [],
            hasAssets: false,
            hasCustomer: false,
            currentAccount: jsCurrentAccount,
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleMenu = this.handleMenu.bind(this);
        this.handleSidebar = this.handleSidebar.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCloseSidebar = this.handleCloseSidebar.bind(this);
        this.showLogonModal = this.showLogonModal.bind(this);
        this.openLogonModal = this.openLogonModal.bind(this);
        this.openUserAccount = this.openUserAccount.bind(this);
        this.handleAccountClick = this.handleAccountClick.bind(this);

        this.openFavorites = this.openFavorites.bind(this);
        this.handleFaves = this.handleFaves.bind(this);
        this.handleCloseFaves = this.handleCloseFaves.bind(this);
        this.onCustomerClick = this.onCustomerClick.bind(this);
        this.onAssetClick = this.onAssetClick.bind(this);

        this.setFavoriteCallback = this.setFavoriteCallback.bind(this);
        this.sortFavorites = this.sortFavorites.bind(this);
        this.setAssetCallback = this.setAssetCallback.bind(this);
        this.getCurrentUser = this.getCurrentUser.bind(this);                 
    }

    /*UNSAFE_componentWillMount() {
        this.getCurrentUser();
    }*/

    componentDidMount() {
        this._isMounted = true;

        this.getCurrentUser();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async getCurrentUser() {
        if (this._isMounted)
            this.setState({ currentAccount: jsCurrentAccount });
        await AccountService.accountinfo()
            .then(response => {
                if (this._isMounted)
                    this.setState({
                        currentAccount: response.data
                    }, async () => {
                        this.props.setCurrentUser(this.state.currentAccount);
                        await userfavoriteService.getuserfavorites(this.state.currentAccount.email)
                            .then(response => {
                                if (this._isMounted)
                                    this.setState({ favoritesList: response.data }, () => {
                                        this.sortFavorites();
                                    });
                            })
                            .catch(e => {
                                console.log(e);
                            });
                    });
            })
            .catch(e => {
                console.log(e);
            });
    }

    sortFavorites() {
        let favorites = this.state.favoritesList;
        if (favorites) {
            let customers = favorites.filter(f => f.favoriteTypeId === 1);
            let assets = favorites.filter(f => f.favoriteTypeId === 2);
            if (this._isMounted)
                this.setState({
                    favoriteCustomers: customers,
                    favoriteAssets: assets
                }, () => {
                        this.props.setFavoritesList(this.state.favoriteCustomers);
                    localStorage.setItem('favoriteCustomers', JSON.stringify(this.state.favoriteCustomers));
                    localStorage.setItem('favoriteAssets', JSON.stringify(this.state.favoriteAssets));
                    localStorage.setItem('favoritesList', JSON.stringify(this.state.favoriteC));
                });
        }
    }

    handleChange(event) {
        if (this._isMounted)
            this.setState({
                auth: event.target.checked
            });
    };

    handleMenu(event) {
        if (this._isMounted)
            this.setState({
                anchorEl: event.currentTarget,
                open: !this.state.open
            });
    };

    handleClose() {
        if (this._isMounted)
            this.setState({
                anchorEl: null,
                open: false
            });
    };

    handleSidebar(event) {
        if (this._isMounted)
            this.setState({
                showSidebar: !this.state.showSidebar
            });
        this.props.handleBurgerClick();
    }

    handleCloseSidebar() {
        if (this._isMounted)
            this.setState({
                showSidebar: false
            })
    }

    handleFaves(event) {
        if (this._isMounted)
            this.setState({
                anchorEl3: event.currentTarget,
                openFavorites: !this.state.openFavorites
            });
    }

    handleCloseFaves() {
        if (this._isMounted)
            this.setState({
                anchorEl3: null,
                openFavorites: false
            });
    }

    showLogonModal(e) {
        if (this._isMounted)
            this.setState({
                showLogon: !this.state.showLogon
            }, () => {
                    if (this.state.showLogon)
                        ModalView("logout");
            });
        window.scrollTo(0, 0);
    }

    openLogonModal(){
        Event("Logout Clicked", "User clicked logout option in user menu", "Logout Clicked");
        this.showLogonModal(null);
        if (this._isMounted)
            this.setState({
                open: false
            });
    }

    openUserAccount() {
        Event("User Account Clicked", "User clicked account option in user menu", "User Account Clicked");
        if (this._isMounted)
            this.setState({
                open: false
            });
    }

    handleAccountClick = (e) => {
        let unsavedChanges = localStorage.getItem("unsavedChanges");
        unsavedChanges = unsavedChanges !== null ? unsavedChanges === 'true' : false;
        
        if (unsavedChanges) {
            console.log(`appBar onAccountClick() - unsavedChanges: ${unsavedChanges}`)
            e.preventDefault();  
            localStorage.setItem("showUnsavedModal", true);
            this.props.setActivePath("/userAccount", true);
        } 
        else {
            this.props.setActivePath(this.props.activePath, true);
        } 
    }

    openFavorites() {
        if (this._isMounted)
            this.setState({
                openFavorites: false
            });
    }

    onCustomerClick = (e, index) => {
        Event("Favorite Customer Clicked", "User clicked one of their favorited customers from the app bar", "Favorite Customer Clicked");
        let fave = this.state.favoriteCustomers[index];

        this.props.setFromFavorites(fave.partnerId, fave.customerId, this.setFavoriteCallback);
    }

    onAssetClick = (e, index) => {
        Event("Favorite Asset Clicked", "User clicked one of their favorited assets from the app bar", "Favorite Asset Clicked");
        let fave = this.props.favoriteAssets[index];
        console.log(fave);

        this.props.setAssetFromFavorites(fave.partnerId, fave.customerId, fave.asset, this.setAssetCallback);
        //create prop function for setting from fave asset
    }

    setFavoriteCallback() {
        if (this._isMounted)
            this.setState({ openFavorites: false });
    }

    setAssetCallback() {
        if (this._isMounted){
            this.getCustomerList(this.state.currentPartnerId);
            this.setState({ openFavorites: false });
        }
        this.props.setActivePath("/assets");
    }

    render() {
        {/*let sidebar = this.state.showSidebar ?
            <Sidebar styles={{ display: 'block', zIndex: '9', width: '225px !important' }} setLoadingFromChild={this.props.setLoadingFromChild} onLogOff={this.onLogOff} currentCustomerId={this.props.currentCustomerId} roles={this.props.roles} loggedInCustomerOrganization={this.props.loggedInCustomerOrganization} isPartnerAdmin={this.props.isPartnerAdmin} setData={this.props.setData} />
            : <div>{null}</div>;*/}

        let favoriteAssets = null;/*this.props.favoriteAssets ?
            <div>
                <Divider sx={{ my: 0.5 }} />
                <ListSubHeader style={{ lineHeight: '2', paddingTop: '5px' }}>Assets</ListSubHeader>
                {this.props.favoriteAssets.map((fave, index) =>
                    <MenuItem key={`${index}-${fave.assetId}`} onClick={(e) => this.onAssetClick(e, index)}>
                        <ListItemText primary={fave.asset.nickname} secondary={fave.asset.macId} style={{ marginBottom: '0px', marginTop: '0px' }}/>
                    </MenuItem>
                )}
            </div>
            : <div>{null}</div>;*/

        let favoriteCustomers = this.props.favoriteCustomers ?
            <div>
                <Divider sx={{ my: 0.5 }} />
                <h6 style={{ lineHeight: '2', paddingTop: '5px' }}>Customers</h6>
                {this.props.favoriteCustomers.map((fave, index) =>
                    <MenuItem key={`${index}-${fave.customerId}`} onClick={(e) => this.onCustomerClick(e, index)}>{fave.customerName}</MenuItem>
                )}
            </div>
            : null;
        
        return (
            <div sx={{
                flexGrow: 1,
            }}>
                <ElevationScroll {...this.props}>
                    <AppBar position="fixed" style={{color: 'black', backgroundColor: 'white', borderBottom: '1px solid #ebeef0', height: '45px' }}>
                        <Toolbar style={{ height: '45px', minHeight: '45px' }}>
                            <div>
                            <IconButton edge="start" color="inherit" aria-label="menu" aria-controls="menu-sidebar" aria-haspopup="true" onClick={this.handleSidebar} sx={(theme) => ({
                                display: 'none',
                                [theme.breakpoints.down('md')]: {
                                    display: 'block',
                                },
                            })}>
                                <MenuIcon/>
                            </IconButton>

                            </div>
                            <div className="navbar-header score-navbar-header" >
                                <a href="/" className="navbar-brand" tabIndex="0">
                                    <img src={require('../../img/resideo-black.png')} style={{ height: '30px' }} className="img-responsive" alt="Resideo Logo" />
                                </a>
                            </div>
                            <Typography variant="h6" sx={{
                                flexGrow: 1,
                            }}></Typography>
                            {this.state.auth && (
                                <div>
                                    <Tooltip title="Favorites" enterDelay={800} leaveDelay={300} style={{ marginTop: '-5px', paddingTop: '-5px', backgroundColor: '#f7f9fa' }}>
                                        <IconButton 
                                            aria-label="favorites of current user"
                                            aria-controls="menu-faves"
                                            aria-haspopup="true"
                                            onClick={this.handleFaves}
                                            style={{ padding: '5px' }}
                                        >
                                            <StarBorderIcon style={{ color: "#0033a0", height: '28px', width: '28px' }} />
                                        </IconButton>
                                    </Tooltip>
                                    <Menu 
                                        id="menu-faves"
                                        anchorEl={this.state.anchorEl3}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={this.state.openFavorites}
                                        onClose={this.handleCloseFaves}
                                    >
                                        <MenuItem onClick={this.openFavorites} >
                                            <Link to="/manageFavorites" style={{ color: 'inherit', marginRight: '25px' }}>
                                                Manage Favorites...
                                            </Link>
                                        </MenuItem>

                                        {favoriteCustomers}
                                        {favoriteAssets}

                                    </Menu>

                                    <IconButton
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={this.handleMenu}
                                    >
                                        <Avatar alt={this.props.userName} src={this.props.logo} style={{ backgroundColor: "#0033a0", height: '30px', width: '30px', fontSize: '16px' }} />
                                    </IconButton>
                                    <Menu 
                                        id="menu-appbar"
                                        anchorEl={this.state.anchorEl}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={this.state.open}
                                        onClose={this.handleClose}
                                    >
                                        <MenuItem onClick={this.openUserAccount} >
                                            <Link to="/useraccount" onClick={e => {this.handleAccountClick(e)}}>
                                                Account
                                            </Link>
                                        </MenuItem>
                                        <MenuItem onClick={this.openLogonModal}>Logout</MenuItem>
                                    </Menu>
                                </div>
                            )}
                        </Toolbar>
                    </AppBar>
                </ElevationScroll>
                <LogOffModal onClick={this.showLogonModal} show={this.state.showLogon} onLogOff={this.props.onLogOff} />
            </div>
        );
    };
}

export default MainAppBar;